<template>
  <div class="mui-conten">
    <div class="imgicon">
      <a href="https://www.litaifushi.com/">
              <img src="../../src/assets/45.jpg" alt style="width: 100%;height:100%" />
      </a>
    </div>
    <div class="register">招商信息登记</div>
    <div class="mui-input-group">
      <div class="bjs">
        <van-form @submit="onSubmit">
          <div class="mui-input-row">
            <van-field
              v-model="formValue.username"
              name="姓名"
              label="姓名"
              placeholder="请输入姓名"
              required
            />
          </div>
          <div class="mui-input-row">
            <van-field
              v-model="formValue.telephone"
              name="电话"
              label="电话"
              placeholder="请输入手机号"
              required
              :rules="[{ pattern: /^1[34578]\d{9}$/, message: '手机号格式错误'},{ required: true }]"
            />
          </div>
          <div class="mui-input-row">
            <van-field v-model="formValue.address" name="地址" label="地址" placeholder="请输入地址" />
          </div>
          <div class="mui-input-row">
            <van-field
              v-model="formValue.remark"
              rows="2"
              autosize
              label="备注"
              type="textarea"
              placeholder="请输入备注"
              show-word-limit
            />
          </div>
          <div class="mui-input-row pad">
            <div class="formsize">
              <span>贵公司所属行业：</span>
            </div>
            <van-radio-group
              v-model="formValue.type"
              v-for="(item,index) in industryList"
              :key="index"
            >
              <van-radio :name="item.name" checked-color="#E9170D">{{item.title}}</van-radio>
            </van-radio-group>
          </div>
          <div class="mui-input-row pad">
            <div class="formsize">
              <span>你对以上哪些感兴趣：</span>
            </div>
            <van-checkbox-group
              v-model="formValue.clothing"
              v-for="(item,index) in interestList"
              :key="index"
            >
              <van-checkbox :name="item.name" checked-color="#E9170D">{{item.title}}</van-checkbox>
            </van-checkbox-group>
          </div>
          <div class="mui-input-row pad">
            <div class="formsize">
              <span>目的：</span>
            </div>
            <van-checkbox-group
              v-model="formValue.obj"
              v-for="(item,index) in objectiveList"
              :key="index"
            >
              <van-checkbox :name="item.name" checked-color="#E9170D">{{item.title}}</van-checkbox>
            </van-checkbox-group>
          </div>
          <div style="margin: 16px;">
            <!-- type="info" -->
            <van-button round block   color="linear-gradient(to right, #E9170D, #BB060C)"  native-type="submit" >提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
    <!-- 悬浮按钮 -->
    <a href="tel:13808379261">
       <div class="FloatingTelephone">
      <img src="../../src/assets/dh.png" alt="" class="FloatingTelephoneimg">
    </div>
    </a>
   
  </div>
</template>
<script>
import { msgAdd } from "../api/home.js";
export default {
  data() {
    return {
      message: "这是一条消息",
      formValue: {
        username: "",
        telephone: "",
        address: "",
        type: null, //行业的
        clothing: [], //兴趣
        obj: [], //目的
        remark: "" //备注
      },
      industryList: [
        { name: 1, title: "经销商代理商渠道商" },
        { name: 2, title: "服装生产企业" },
        { name: 3, title: "面料辅料商" },
        { name: 4, title: "政府机构企事业单位" },
        { name: 5, title: "其他" }
      ],
      interestList: [
        { name: 1, title: "职业装团服" },
        { name: 2, title: "校服园服" },
        { name: 3, title: "面辅料" },
        { name: 4, title: "其他" }
      ],
      objectiveList: [
        { name: 1, title: "了解行业动态" },
        { name: 2, title: "寻找合作供应商" },
        { name: 3, title: "招商加盟" },
        { name: 4, title: "其他" }
      ]
    };
  },
  methods: {
    scrollto() {
      document.body.scrollTop = document.documentElement.scrollTop = window.pageYOffset = 0;
    },
    onSubmit(values) {
      if (this.formValue.username == "") {
        this.$toast("请输入姓名");
        return;
      }
      if (this.formValue.telephone == "") {
        this.$toast("请输入联系方式");
        return;
      }
      if (this.formValue.type == null) {
        this.$toast("请选择所属行业");
        return;
      }
      if (this.formValue.clothing.length == 0) {
        this.$toast("请选择感兴趣内容");
        return;
      }
      if (this.formValue.length == 0) {
        this.$toast("请选择参观目的");
        return;
      }
      let params = {
        name: this.formValue.username,
        phone: this.formValue.telephone,
        address: this.formValue.address,
        type: this.formValue.type,
        clothing: this.formValue.clothing.join(","),
        obj: this.formValue.obj.join(","),
        remark: this.formValue.remark
      };
      msgAdd(params).then(res => {
        if (res.code == 200) {
          this.$toast("提交成功");
          this.formValue = {
            username: "",
            telephone: "",
            address: "",
            type: null, //行业的
            clothing: [], //兴趣
            obj: [], //目的
            remark: "" //备注
          };
          this.scrollto()
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
@media (max-width: 750px) {
  .pad {
    padding: 15px 10px;
  }
  .mt {
    margin-bottom: 10px;
  }
  .mui-content {
    width: 100vw;
    height: 200px;
    padding: 0px 0px 84px 0px;
    background-color: rgb(92, 26, 26);
  }
  .imgicon {
    width: 100%;
  }
  .register {
    width: 100%;
    padding: 0px;
    font-size: 18px;
    margin-top: 8px;
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .mui-input-group {
    border-top: 1px solid #e7e7e9;
  }
  .bjs {
    padding: 10px;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .mui-input-row {
    border-radius: 4px;
    margin-top: 0px;
    padding-top: 5px;
    min-height: 50px;
    box-shadow: 0px 0px 5px 2px #e6e6e6;
    margin-bottom: 10px;
  }
  ::v-deep .van-cell {
    font-size: 16px;
  }
  ::v-deep .van-field__label {
    color: #000000;
  }
  ::v-deep .van-radio {
    margin-bottom: 8px;
  }
  ::v-deep .van-checkbox {
    margin-bottom: 8px;
  }
  .formsize {
    font-size: 16px;
    margin-bottom: 12px;
    span {
      position: relative;
      &::after {
        position: absolute;
        right: 0;
        top: 2px;
        color: #ee0a24;
        font-size: 16px;
        content: "*";
      }
    }
  }
  .FloatingTelephone{
    height: 40px;
		width: 40px;
		position: fixed;
		bottom: 65px;
		right: 24px;
    background:#E9170D ;
    border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .FloatingTelephoneimg{
    width: 24px;
    height: 24px;
  }
}
</style>
