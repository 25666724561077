//引入
import axios from "./axios.js"; //路劲自己修改
// 导航栏
export const msgAdd = (params) => {
    return axios({
        url: "home/link/msg_add",
        method: "post" ,  
        data: params
    })
}


                   
